import React from 'react'

import { ScreenSizeContextProvider, SidebarContextProvider } from 'contexts'

// All Global Provides that needed to wrap the App in here.
export const Providers: React.FC<any> = ({ children }) => {
  return (
    <ScreenSizeContextProvider>
      <SidebarContextProvider>{children}</SidebarContextProvider>
    </ScreenSizeContextProvider>
  )
}
