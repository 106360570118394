import { createContext, useCallback, useContext, useState } from 'react'

interface ISidebarContext {
  isShow: boolean
  handleIsShow: (_isShow?: boolean) => void
}

const initialState = { isShow: true, handleIsShow: () => null }
const SidebarContext = createContext<ISidebarContext>(initialState)
export const useSidebarContext = () => useContext(SidebarContext)

export const SidebarContextProvider: React.FC<any> = ({ children }) => {
  const [isShow, setIsShow] = useState<boolean>(() => localStorage.getItem('isShowSidebar') !== 'false')
  const handleIsShow = useCallback((_isShow?: boolean) => {
    setIsShow(_isShow ?? false)
    localStorage.setItem('isShowSidebar', _isShow ? 'true' : 'false')
  }, [])

  return <SidebarContext.Provider value={{ isShow, handleIsShow }}>{children}</SidebarContext.Provider>
}
